import { Progress } from "antd";
import "./style.scss";
import { IWork } from "../../../icons/IWork";
import { ITime } from "../../../icons/ITime";
import WorkerLayout from "../workLayout";
import { useEffect, useState, useRef, useCallback } from "react";
import axios from "config/axios";
import { useLocation, useNavigate } from "react-router-dom";
import constant from "constant/action";
import { useDispatch } from "react-redux";
import { pushCachedProcessId } from "store/duck/adminWorkingDataSlice";
import NewAvatarImage, { ICharacterFullImage } from "components/NewAvatarImage";
import LevelTag from "components/LevelTag";
import LevelProgressBar from "components/LevelProgressBar";
import NotiEffect from "components/NotiEffect";
import libAxios from "axios";
import { isEmptyObject } from "utils/helper";
import { handleSaveLog } from "services/log";
import moment from "moment-timezone";
import CycleTimeSpeedmeterWrapper from "components/CycleTimeSpeedmeterWrapper";

interface UserProductStatus {
  new_item?: boolean;
  is_level_up?: boolean;
}

interface UserStatus {
  new_item?: boolean;
  is_level_up?: boolean;
}

interface UserProductParams {
  user_product_id: number;
  new_item?: boolean;
  is_level_up?: boolean;
}

interface UserLevelParams {
  user_id: number;
  new_item?: boolean;
  is_level_up?: boolean;
}

const Work = () => {
  const [showNewItem, setShowNewItem] = useState(false);
  // const [allUserData, setAllUserData] = useState<any[]>([]);
  const [currentUserRecord, setCurrentUserRecord] = useState<any>(null);
  const [currentLineDetail, setCurrentLineDetail] = useState<any>(null);
  const [currentProcessDetail, setCurrentProcessDetail] = useState<any>(null);
  const [targetCycleTimeRange, setTargetCycleTimeRange] = useState<number>(0);
  const [targetCycleTimeType, setTargetCycleTimeType] = useState<number>(0);
  const [fullCharacterImage, setFullCharacterImage] = useState<ICharacterFullImage | null>(null);
  const [itemZIndex, setItemZIndex] = useState<{ hat: number, shoe: number, cloth: number }>({ hat: 2, shoe: 3, cloth: 4 })
  const [percent, setPercent] = useState<number>(0);
  const navigate = useNavigate();
  // const moveNextRecordTimer = useRef<any>(null);
  const backToManufacturingStartTimer = useRef<any>(null);
  const [isLevelUp, setIsLevelUp] = useState<boolean>(false);
  const [isUserLevelUp, setIsUserLevelUp] = useState<boolean>(false);
  const [showUserNewItem, setShowUserNewItem] = useState<boolean>(false);
  const [userBothNewItemAndLevelUp, setUserBothNewItemAndLevelUp] = useState<boolean>(false);
  const [checkUserLevelAndItem, setCheckUserLevelAndItem] = useState<boolean>(false);
  const [bothNewItemAndLevelUp, setBothNewItemAndLevelUp] = useState<boolean>(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryLineId = searchParams.get("line_id");
  const machiningLineId = localStorage.getItem("selected_line") || queryLineId;
  const queryProcessId = searchParams.get("process_id");
  const machiningProcessId = localStorage.getItem('selected_process') || queryProcessId;
  const dispatch = useDispatch();
  const callInterval = useRef<any>();
  const cachedProcesses = localStorage.getItem("cachedProcesses");
  const cachedTargetCycle = localStorage.getItem("cachedTargetCycle");

  const timeToOut = localStorage.getItem("timeToOut");

  const fetchNewlyImportWorkingData = useCallback(async () => {
    if (
      machiningLineId === null ||
      machiningLineId === undefined ||
      machiningProcessId === null ||
      machiningProcessId === undefined
    ) {
      return;
    }

    try {
      const response = await axios.get(constant.ADMIN_WORKING_PROCESS, {
        params: {
          machining_line_id: machiningLineId,
          machining_process_id: +machiningProcessId,
        },
      });
      if (response) {
        const { data } = response;
        if (isEmptyObject(data)) {
          return;
        }
        if (!cachedProcesses) {
          handleSaveLog({
            page: "/admin/work",
            type: "SUCCESS",
            param_machining_process_id: +machiningProcessId,
            account_id: data.account_id,
            user_product_id: data.machining_processes_detail?.user_product_id,
            product_name: data.machining_processes_detail?.name,
            conditional: "no-cached",
          });
          setCurrentUserRecord(data);
          return;
        }
        const jsonCachedProcesses = JSON.parse(cachedProcesses);
        const jsonCachedTargetCycle = cachedTargetCycle ? JSON.parse(cachedTargetCycle) : { targetCycleTimeRange: 0, targetCycleTimeType: 0 };
        const workingProcessData = data.machining_processes_detail;

        if (
          (
            jsonCachedProcesses.user_product_id === workingProcessData.user_product_id &&
            jsonCachedProcesses.total_product !== workingProcessData.total_product
          ) ||
          jsonCachedProcesses.user_product_id !== workingProcessData.user_product_id ||
          // 標準CT, 実績, CT範囲
          jsonCachedProcesses.current_cycle_time !== workingProcessData.current_cycle_time ||
          jsonCachedProcesses.standard_cycle_time !== workingProcessData.standard_cycle_time ||
          jsonCachedProcesses.cycle_time_normal_range !== workingProcessData.cycle_time_normal_range ||
          // 目標値
          jsonCachedTargetCycle.targetCycleTimeRange !== data.target_time_range ||
          jsonCachedTargetCycle.targetCycleTimeType !== data.target_time_type
        ) {
          if (callInterval.current) clearInterval(callInterval.current);

          handleSaveLog({
            page: "/admin/work",
            type: "SUCCESS",
            param_machining_process_id: +machiningProcessId,
            account_id: data.account_id,
            user_product_id: data.machining_processes_detail?.user_product_id,
            product_name: data.machining_processes_detail?.name,
            conditional: "cached",
          });
          setCurrentUserRecord(data);
          return;
        }
      }
    } catch (error) {
      if (libAxios.isAxiosError(error)) {
        const errorData = error.response?.data?.errors;
        handleSaveLog({
          page: "/admin/work",
          type: "ERROR",
          param_machining_process_id: +machiningProcessId,
          ...errorData,
        });
      }
    }
  }, [cachedProcesses, machiningLineId, machiningProcessId, cachedTargetCycle]);

  useEffect(() => {
    fetchNewlyImportWorkingData();
  }, [fetchNewlyImportWorkingData]);

  useEffect(() => {
    if (currentUserRecord !== null) {
      setCurrentLineDetail(currentUserRecord.machining_line);
      setCurrentProcessDetail(currentUserRecord.machining_processes_detail);
      setTargetCycleTimeRange(currentUserRecord.target_time_range ?? 0);
      setTargetCycleTimeType(currentUserRecord.target_time_type ?? 0);

      const mainCharacter = currentUserRecord.user_characters?.find((item: any) => item.is_main === true);

      const bodyImage = mainCharacter.character.image_path;
      handleSaveLog({
        page: "/admin/work",
        type: "SET_STATE",
        data: bodyImage,
        name: "body image",
      });

      const hatItem = mainCharacter.user_character_items.find((item: any) => item?.user_items?.item?.item_type === "hat");
      const hatImage = hatItem?.user_items?.item?.asset?.image_path || undefined;
      handleSaveLog({
        page: "/admin/work",
        type: "SET_STATE",
        data: hatImage,
        name: "hat image",
      });

      const clothItem = mainCharacter.user_character_items.find((item: any) => item?.user_items?.item?.item_type === "cloth");
      const clothImage = clothItem?.user_items?.item?.asset?.image_path || undefined;
      handleSaveLog({
        page: "/admin/work",
        type: "SET_STATE",
        data: clothImage,
        name: "cloth image",
      });
      
      const shoeItem = mainCharacter.user_character_items.find((item: any) => item?.user_items?.item?.item_type === "shoe");
      const shoeImage = shoeItem?.user_items?.item?.asset?.image_path || undefined;
      handleSaveLog({
        page: "/admin/work",
        type: "SET_STATE",
        data: shoeImage,
        name: "shoe image",
      });

      setFullCharacterImage({
        body: bodyImage,
        hat: hatImage,
        cloth: clothImage,
        shoe: shoeImage,
        characterId: mainCharacter?.character?.id || 0,
      });

      setItemZIndex({
        hat: hatItem.user_items.item.z_index,
        shoe: shoeItem.user_items.item.z_index,
        cloth: clothItem.user_items.item.z_index,
      });
    }
  }, [currentUserRecord]);


  const handleUpdateUserProduct = useCallback(async ({ new_item = undefined, is_level_up = undefined }: UserProductStatus) => {
    if (!currentProcessDetail.user_product_id) {
      return;
    }
    try {
      const data: UserProductParams = {
        user_product_id: currentProcessDetail.user_product_id,
      };
      if (new_item !== undefined) {
        data['new_item'] = new_item;
      }
      if (is_level_up !== undefined) {
        data['is_level_up'] = is_level_up;
      }
      const url = constant.ADMIN_USERS_PRODUCT;
      await axios.put(url, data);
    } catch (error) {
      // 
    }
  }, [currentProcessDetail]);

  const handleUpdateUserLevelandItem = useCallback(async ({ new_item = undefined, is_level_up = undefined }: UserStatus) => {
    if (!currentUserRecord && !currentUserRecord.id) {
      return;
    }
    try {
      const data: UserLevelParams = {
        user_id: +currentUserRecord.id,
      }
      if (new_item !== undefined) {
        data['new_item'] = new_item;
      }
      if (is_level_up !== undefined) {
        data['is_level_up'] = is_level_up;
      }
      const url = constant.ADMIN_USER_LEVEL;
      await axios.put(url, data);
    } catch (error) {
      // 
    }
  }, [currentUserRecord])

  useEffect(() => {
    return () => {
      clearInterval(callInterval.current);
    }
  });

  useEffect(() => {
    if (!currentProcessDetail) {
      return;
    }
    if (currentProcessDetail) {
      if (currentProcessDetail.is_level_up && currentProcessDetail.have_new_item) {
        handleSaveLog({
          page: "/admin/work",
          type: "SET_STATE",
          data: currentProcessDetail.is_level_up && currentProcessDetail.have_new_item,
          name: "process level up and process new item",
        });
        setBothNewItemAndLevelUp(true);
        setIsLevelUp(true);
        handleUpdateUserProduct({ is_level_up: false, new_item: false });
        return;
      }
      if (!currentProcessDetail.is_level_up && currentProcessDetail.have_new_item) {
        handleSaveLog({
          page: "/admin/work",
          type: "SET_STATE",
          data: currentProcessDetail.have_new_item,
          name: "process new item",
        });
        setShowNewItem(true);
        handleUpdateUserProduct({ new_item: false });
        return;
      }
      if (currentProcessDetail && currentProcessDetail.is_level_up && !currentProcessDetail.have_new_item) {
        handleSaveLog({
          page: "/admin/work",
          type: "SET_STATE",
          data: currentProcessDetail.is_level_up,
          name: "process level up",
        });
        setIsLevelUp(true);
        handleUpdateUserProduct({ is_level_up: false });
        return;
      }
      setCheckUserLevelAndItem(true);
    }
  }, [currentProcessDetail, handleUpdateUserProduct]);
  useEffect(() => {
    if (!currentProcessDetail) {
      return;
    }
    if (currentProcessDetail && typeof currentProcessDetail.boosting_date === "number") {
      const boostingDate = currentProcessDetail.boosting_date;
      handleSaveLog({
        page: "/admin/work",
        type: "SET_STATE",
        data: currentProcessDetail.boosting_date,
        name: "process boosting date",
      });
      setPercent(20 * boostingDate);
    }
  }, [currentProcessDetail]);
  useEffect(() => {
    if (!currentProcessDetail) {
      return;
    }
    if (currentProcessDetail && currentProcessDetail.user_product_id) {
      dispatch(pushCachedProcessId(currentProcessDetail.user_product_id));

      const cachingProcess = currentProcessDetail;
      localStorage.setItem("cachedProcesses", JSON.stringify(cachingProcess));
    }
  }, [currentProcessDetail, dispatch]);
  useEffect(() => {
    localStorage.setItem("cachedTargetCycle", JSON.stringify({ 
      targetCycleTimeRange: targetCycleTimeRange,
      targetCycleTimeType: targetCycleTimeType
    }));
  }, [targetCycleTimeRange, targetCycleTimeType]);
  useEffect(() => {
    let newItemTimeout: NodeJS.Timeout;
    if (showNewItem) {
      newItemTimeout = setTimeout(() => {
        setShowNewItem(false);
        setCheckUserLevelAndItem(true);
      }, 10000);
    }
    return () => {
      clearTimeout(newItemTimeout);
    }
  }, [showNewItem])
  useEffect(() => {
    let levelUpTimeout: NodeJS.Timeout;
    if (isLevelUp && bothNewItemAndLevelUp) {
      levelUpTimeout = setTimeout(() => {
        setIsLevelUp(false);
        setBothNewItemAndLevelUp(false);
        setShowNewItem(true);
      }, 10000);
    } else if (isLevelUp && !bothNewItemAndLevelUp) {
      levelUpTimeout = setTimeout(() => {
        setIsLevelUp(false);
        setCheckUserLevelAndItem(true);
      }, 10000);
    }
    return () => {
      clearTimeout(levelUpTimeout);
    }
  }, [isLevelUp, bothNewItemAndLevelUp])

  useEffect(() => {
    if (!currentUserRecord || !currentProcessDetail) {
      return;
    }

    if (!checkUserLevelAndItem) {
      return;
    }
    if (currentUserRecord.is_level_up && currentUserRecord.new_item) {
      setUserBothNewItemAndLevelUp(true);
      setIsUserLevelUp(true);
      handleUpdateUserLevelandItem({ is_level_up: false, new_item: false });
      return;
    }
    if (!currentUserRecord.is_level_up && currentUserRecord.new_item) {
      setShowUserNewItem(true);
      handleUpdateUserLevelandItem({ new_item: false });
      return;
    }
    if (currentUserRecord.is_level_up && !currentUserRecord.new_item) {
      setIsUserLevelUp(true);
      handleUpdateUserLevelandItem({ is_level_up: false });
      return;
    }
    setCheckUserLevelAndItem(false);
  }, [currentUserRecord, currentProcessDetail, checkUserLevelAndItem, handleUpdateUserLevelandItem]);

  useEffect(() => {
    let userNewItemTimeout: NodeJS.Timeout;
    if (showUserNewItem) {
      setCheckUserLevelAndItem(false);
      userNewItemTimeout = setTimeout(() => {
        setShowUserNewItem(false);
      }, 10000);
    }
    return () => {
      clearTimeout(userNewItemTimeout);
    }
  }, [showUserNewItem]);
  useEffect(() => {
    let userLevelUpTimeout: NodeJS.Timeout;
    if (isUserLevelUp && userBothNewItemAndLevelUp) {
      setCheckUserLevelAndItem(false);
      userLevelUpTimeout = setTimeout(() => {
        setIsUserLevelUp(false);
        setUserBothNewItemAndLevelUp(false);
        setShowUserNewItem(true);
      }, 10000);
    } else if (isUserLevelUp && !userBothNewItemAndLevelUp) {
      setCheckUserLevelAndItem(false);
      userLevelUpTimeout = setTimeout(() => {
        setIsUserLevelUp(false);
      }, 10000);
    }
    return () => {
      clearTimeout(userLevelUpTimeout);
    }
  }, [isUserLevelUp, userBothNewItemAndLevelUp])

  useEffect(() => {
    if (isLevelUp || showNewItem || isUserLevelUp || showUserNewItem) {
      return;
    }
    if (!cachedProcesses) {
      return;
    }
  }, [cachedProcesses, isLevelUp, showNewItem, isUserLevelUp, showUserNewItem, fetchNewlyImportWorkingData, machiningLineId, machiningProcessId]);

  useEffect(() => {
    callInterval.current = setInterval(() => {
      console.log("fetchNewlyImportWorkingData");
      fetchNewlyImportWorkingData();
    }, 10 * 1000);
    return () => {
      clearInterval(callInterval.current);
    };
  });

  useEffect(() => {
    if (!currentUserRecord || !currentProcessDetail) {
      return;
    }

    let now = moment();
    let remainingMilliseconds: number = 0;
    
    if (!timeToOut) {
      const lastImportTime = moment(currentProcessDetail.last_import_time);
      const timeAfter10min = lastImportTime.add(10, "minutes");
      const timeAfter10minInString = timeAfter10min.tz("Asia/Tokyo").format();
      localStorage.setItem("timeToOut", timeAfter10minInString);

      if (timeAfter10min.isAfter(now)) {
        remainingMilliseconds = timeAfter10min.diff(now);
      } else {
        // navigate(`/admin/manufacturing-start?process_id=${machiningProcessId}`);
      }
    } else {
      const timeAfter10min = moment(timeToOut);
      if (timeAfter10min.isAfter(now)) {
        remainingMilliseconds = timeAfter10min.diff(now);
      } else {
        // navigate(`/admin/manufacturing-start?process_id=${machiningProcessId}`);
      }
    }

    const goToManufacturingStart = () => {
      // navigate(`/admin/manufacturing-start?process_id=${machiningProcessId}`);
    };

    if (!backToManufacturingStartTimer.current) {
      backToManufacturingStartTimer.current = setTimeout(goToManufacturingStart, remainingMilliseconds);
    }
  }, [currentUserRecord, currentProcessDetail, machiningProcessId, navigate, timeToOut]);

  useEffect(() => {
    return () => {
      clearTimeout(backToManufacturingStartTimer.current);
      localStorage.removeItem("timeToOut");
    }
  }, [])
  
  useEffect(() => {
    const handleResetCachedProcess = () => {
      localStorage.removeItem("cachedProcesses");
    }
    window.addEventListener("beforeunload", handleResetCachedProcess);
    return () => {
      window.removeEventListener("beforeunload", handleResetCachedProcess);
    }
  }, []);

  useEffect(() => {
    handleSaveLog({
      page: "/admin/work",
      type: "INITIAL_RENDER",
    });
  }, []);

  return (
    <>
      <div className="adminWorkContainer">
        <WorkerLayout currentUserRecord={currentUserRecord}>
          <div className="work-container">
            <div className="leftSection">
              <div className="processNameLevel">
                <div className="inner_wrapper mb-3">
                  <p className="process_Text" style={{ fontSize: currentLineDetail?.line_jpn?.length > 7 ? "20px" : "30px" }}>
                    {currentLineDetail?.line_jpn || ''}
                  </p>
                </div>
                <div className="inner_wrapper mb-3">
                  <p className="process_Text" style={{ fontSize: currentProcessDetail?.process_name?.length > 7 ? "20px" : "30px" }}>
                    {currentProcessDetail?.process_name || ''}
                  </p>
                </div>
                <div className="inner_wrapper mb-6">
                  <p className="process_Name" style={{ fontSize: currentProcessDetail?.model_name?.length > 7 ? "20px" : "30px" }}>
                    {currentProcessDetail?.model_name || ''}
                  </p>
                </div>
              </div>
              <div className="levelBoostingWrapper">
                <div className="mb-12">
                  <LevelProgressBar
                    type="worker"
                    level={(+currentUserRecord?.level || 0) + 1} 
                    percentToBoostLevel={+currentUserRecord?.percent_to_up_level || 0} 
                  />
                </div>
                <div className="mb-16">
                  <LevelProgressBar 
                    type="process" 
                    level={(currentProcessDetail?.level || 0) + 1} 
                    percentToBoostLevel={currentProcessDetail?.percent_to_boost_level} 
                  />
                </div>
                <div className="mb-3">
                  <LevelTag type={"boost"} screen="working" />
                </div>
                <div className="boostingImage">
                  <div className="boostingWrapper">
                    <Progress
                      strokeColor="#FA9158"
                      trailColor="#C7C7C7"
                      steps={10}
                      percent={percent}
                      type="line"
                      showInfo={false}
                      size={[19, 30]}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="rightSection">
              <div className="cycleTimeAvater">
                <div className="cycleSpeedmeter">
                  <CycleTimeSpeedmeterWrapper
                    currentCycleTime={currentProcessDetail?.current_cycle_time || 0}
                    standardCycleTime={currentProcessDetail?.standard_cycle_time || 0}
                    cycleTimeNormalRange={currentProcessDetail?.cycle_time_normal_range || 0}
                    targetCycleTimeRange={targetCycleTimeRange}
                    targetCycleTimeType={targetCycleTimeType}
                  />
                </div>
                {
                  fullCharacterImage !== null && (
                    <NewAvatarImage 
                      fullImage={fullCharacterImage}
                      itemZIndex={itemZIndex}
                    />
                  )
                }
              </div>
              <div className="totalDataWrapper">
                <div className="totalDataRows">
                  <div className="totalDataParts">
                    <div className="total-number-work">
                      <div className="totalNumberWork__innerWrapper">
                        <div className="legend">
                          <IWork />
                          <p>
                            総作業台数
                          </p>
                        </div>
                        <p className="data">
                          {currentProcessDetail?.total_product}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="totalDataParts">
                    <div className="total-number-work total-number-work-sub">
                      <div className="totalNumberWork__innerWrapper">
                        <div className="legend">
                          <IWork />
                          <p>
                            今日の作業台数
                          </p>
                        </div>
                        <p className="data">
                          {currentProcessDetail?.total_product_in_day}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="totalDataRows">
                  <div className="totalDataParts">
                    <div className="total-number-time">
                      <div className="totalNumberTime__innerWrapper">
                        <div className="legend">
                          <ITime />
                          <p>
                            総作業時間
                          </p>
                        </div>

                        <p className="data">
                          {((currentProcessDetail?.total_cumulative_time || 0) / 3600).toFixed(2)}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="totalDataParts">
                    <div className="total-number-time total-number-time-sub">
                      <div className="totalNumberTime__innerWrapper">
                        <div className="legend">
                          <ITime />
                          <p>
                            今日の作業時間
                          </p>
                        </div>
                        <p className="data">
                          {((currentProcessDetail?.total_cycle_time_in_day || 0) / 3600).toFixed(2)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </WorkerLayout>
        {showNewItem && (
          <NotiEffect type="new-item" newItemType="process-item" process={currentProcessDetail} />
        )}
        {showUserNewItem && (
          <NotiEffect type="new-item" newItemType="user-item" user={currentUserRecord} />
        )}
        {isUserLevelUp && (
          <NotiEffect type="level-up" levelType="user-level" />
        )}
        {isLevelUp && (
          <NotiEffect type="level-up" levelType="process-level" />
        )}
      </div>
    </>
  );
};
export default Work;
