import React, { useEffect, useState } from "react";
import { getRoundedValueOfCycleTime } from "utils/calculation";
import CycleTimeSpeedmeter from "components/CycleTimeSpeedmeter";

interface CycleTimeSpeedmeterWrapperProps {
  currentCycleTime: number;
  standardCycleTime: number;
  cycleTimeNormalRange: number;
  targetCycleTimeRange: number;
  targetCycleTimeType: number;
}

const CycleTimeSpeedmeterWrapper: React.FC<CycleTimeSpeedmeterWrapperProps> = ({
  currentCycleTime,
  standardCycleTime,
  cycleTimeNormalRange,
  targetCycleTimeRange,
  targetCycleTimeType
}) => {
  const [calcCurrentCycleTime, setCalcCurrentCycleTime] = useState<number>(0);
  const [calcStandardCycleTime, setCalcStandardCycleTime] = useState<number>(0);
  const [regendText, setRegendText] = useState<string>("秒");

  useEffect(() => {
    let tmpRegendText = "秒";
    let tmpCurrentCycleTime = currentCycleTime;
    let tmpStandardCycleTime = standardCycleTime;

    while (true) {
      const upperRange = (1 + (cycleTimeNormalRange / 100));
      const upperBoundValue = getRoundedValueOfCycleTime(tmpStandardCycleTime, upperRange);

      const lowerRange = (1 - (cycleTimeNormalRange / 100));
      let lowerBoundValue = getRoundedValueOfCycleTime(tmpStandardCycleTime, lowerRange);
      
      const boundDiff = (((upperBoundValue - lowerBoundValue) / 0.6) - (upperBoundValue - lowerBoundValue)) / 2;
      let endValue = Math.round(upperBoundValue + boundDiff);

      // 最大値が1000未満の場合は単位返還しない
      if (endValue < 1000) {
        break;
      }

      // 単位変換
      if (tmpRegendText === "秒") {
        tmpRegendText = "分";
      } else if (tmpRegendText === "分") {
        tmpRegendText = "時";
      // 時を超える単位変換はしない
      } else {
        break;
      }

      // 値を単位変換
      tmpCurrentCycleTime = tmpCurrentCycleTime / 60;
      tmpStandardCycleTime = tmpStandardCycleTime / 60;
    }

    tmpCurrentCycleTime = Math.round(tmpCurrentCycleTime * 100) / 100;

    setRegendText(tmpRegendText);
    setCalcCurrentCycleTime(tmpCurrentCycleTime);
    setCalcStandardCycleTime(tmpStandardCycleTime);

  }, [currentCycleTime, standardCycleTime, cycleTimeNormalRange])

  return (
    <>
      <CycleTimeSpeedmeter
        key={calcCurrentCycleTime + regendText}
        currentCycleTime={calcCurrentCycleTime}
        standardCycleTime={Math.round(calcStandardCycleTime)}
        cycleTimeNormalRange={cycleTimeNormalRange}
        targetCycleTimeRange={targetCycleTimeRange}
        targetCycleTimeType={targetCycleTimeType}
        regendText={regendText}
      />
    </>
  )
}

export default CycleTimeSpeedmeterWrapper;