import React, { useEffect, useState, useCallback } from "react";
import "./style.scss";
import ReactSpeedometer from "react-d3-speedometer"
import { Spin } from "antd";

interface CycleTimeSpeedmeterProps {
  currentCycleTime: number;
  standardCycleTime: number;
  cycleTimeNormalRange: number;
  targetCycleTimeRange: number;
  targetCycleTimeType: number;
  regendText: string;
}

// メーター上の各CT値用の線の太さをメーター幅に対しての比率で設定
const LINE_WIDTH_RATIO = 0.003;
// メーター上の目盛線の太さをCT値用の線の太さに対しての比率で設定
const LINE_WIDTH_THIN_RATIO = 0.3;
// メーター上で各CT値の凡例等が他項目と重ならないように調整する幅をメーター幅に対しての比率で設定
const ADJUST_SUFFER_RATIO = 0.04;
// 凡例を表示する位置を値の位置を基準に設定
const LEGEND_POSITION = -20;

const CycleTimeSpeedmeter: React.FC<CycleTimeSpeedmeterProps> = ({
  currentCycleTime,
  standardCycleTime,
  cycleTimeNormalRange,
  targetCycleTimeRange,
  targetCycleTimeType,
  regendText
}) => {
  const [customSegmentStops, setCustomSegmentStops] = useState<any[]>([]);
  const [calcCurrentCycleTime, setCalcCurrentCycleTime] = useState<number>(0);
  const [currentValueText, setCurrentValueText] = useState<string>("");
  const [segmentColors, setSegmentColors] = useState<string[]>([]);
  const [showSpeedmeter, setShowSpeedmeter] = useState<boolean>(false);
  const [stopMainValues, setStopMainValues] = useState<any[]>([]);

  // CT上限下限値の範囲を算出
  const getBoundValue = useCallback(() => {
    const range = cycleTimeNormalRange / 100;
    return Math.round(standardCycleTime * range);
  }, [cycleTimeNormalRange, standardCycleTime])

  // CT上限値を算出
  const getUpperBoundValue = useCallback(() => {
    return standardCycleTime + getBoundValue();
  }, [standardCycleTime, getBoundValue])

  // CT下限値を算出
  const getLowerBoundValue = useCallback(() => {
    return standardCycleTime - getBoundValue();
  }, [standardCycleTime, getBoundValue])

  // 目標CTを算出
  const getTargetCycleTime = useCallback(() => {
    const range = targetCycleTimeRange / 100;
    const bound = Math.round(standardCycleTime * range);
    if (targetCycleTimeType === 1) {
      return standardCycleTime - bound;
    }
    return standardCycleTime + bound;
  }, [targetCycleTimeRange, targetCycleTimeType, standardCycleTime])

  const adjustSVG = useCallback((svgElement: SVGSVGElement) => {
    const width = svgElement.width.baseVal.value;
    let height = width / 1.4;

    if (height > window.innerHeight * 0.61) {
      height = window.innerHeight * 0.61;
    }

    svgElement.removeAttribute("style");
    svgElement.setAttribute('preserveAspectRatio', 'none');
    svgElement.style.height = height + 'px';
    svgElement.style.overflow = 'visible';

    const viewBoxHeight = height * 0.95;
    const viewBoxY = height * -0.1;

    // viewBoxの設定を変更
    svgElement.setAttribute("viewBox", `0 ${viewBoxY} ${width + 13} ${viewBoxHeight}`);
  }, []);

  const generateLegend = useCallback((svgElement: SVGSVGElement, text: string) => {

    const originalG = svgElement.querySelector<SVGGElement>(".pointer");
    if (!originalG) {
      return;
    }

    const clonedG = originalG.cloneNode(true) as SVGGElement;
    const pathElement = clonedG.querySelector("path");
    if (pathElement) {
      clonedG.removeChild(pathElement);
    }

    clonedG.removeAttribute("style");

    const isTargetCtGreater = stopMainValues.length && getTargetCycleTime() > stopMainValues[stopMainValues.length - 1];
    const positonX = svgElement.width.baseVal.value - (isTargetCtGreater ? 5 : 0);
    const adjustY = isTargetCtGreater ? 25 : -7;

    const transformAttr = originalG.getAttribute("transform");
    let newTransform = `translate(${positonX}, ${adjustY})`;
    if (transformAttr) {
      const match = transformAttr.match(/translate\(\s*([\d.-]+)\s*,\s*([\d.-]+)\s*\)/);
      if (match) {
        const [, , y] = match;
        newTransform = `translate(${positonX}, ${Number(y) + adjustY})`;
      }
    }
    clonedG.setAttribute("transform", newTransform);

    const textElement = document.createElementNS("http://www.w3.org/2000/svg", "text");
    textElement.setAttribute("x", "0");
    textElement.setAttribute("y", "0");
    textElement.setAttribute("text-anchor", "middle");
    textElement.setAttribute("dominant-baseline", "middle");
    textElement.style.fill = 'rgba(123, 123, 123, 1)';
    textElement.style.fontSize = "19px";
    textElement.textContent = text;

    clonedG.appendChild(textElement);
    svgElement.appendChild(clonedG);
  }, [stopMainValues, getTargetCycleTime])

  const checkTargetCtValueDiff = useCallback((value: number) => {
    const range = stopMainValues[stopMainValues.length - 1] - stopMainValues[0];
    const adjustSuffer = range * (ADJUST_SUFFER_RATIO + 0.005);
    const diff = value - getTargetCycleTime();
    const adjustRatio = diff !== 0 ? adjustSuffer / Math.abs(diff) : 0;
    const adjustSpace = adjustRatio !== 0 ? 22 / adjustRatio : 0;

    if (0 < diff && diff < adjustSuffer) {
      return adjustSpace !== 0 ? adjustSpace : 0.1;
    }
    if (0 >= diff && diff > -adjustSuffer) {
      return adjustSpace !== 0 ? -adjustSpace : -0.1;
    }
    return 0;
  }, [stopMainValues, getTargetCycleTime])

  const generateTargetCtMultipleText = useCallback((value: number, text: string) => {
    const check = checkTargetCtValueDiff(value);

    if (check > 0) {
      return {
        text: `目標CT ${text}`,
        position: check
      };
    }
    if (check < 0) {
      return {
        text: `${text} 目標CT`,
        position: check
      };
    }
    return null;
  }, [checkTargetCtValueDiff])

  const generateTargetCtMultipleValue = useCallback((value: number) => {
    const tmpTargetCycleTime = getTargetCycleTime();
    const check = checkTargetCtValueDiff(value);

    if (check > 0) {
      return `${tmpTargetCycleTime} ${value}`;
    }
    if (check < 0) {
      return `${value} ${tmpTargetCycleTime}`;
    }
    return value.toString();
  }, [getTargetCycleTime, checkTargetCtValueDiff])

  const getTransformPosition = (el: SVGTextElement) => {
    const transformAttr = el.getAttribute("transform");
    if (transformAttr) {
      const translateMatch = transformAttr.match(/translate\(([-\d.]+),\s*([-\d.]+)\)/);
      if (translateMatch) {
        const x = parseFloat(translateMatch[1]);
        const y = parseFloat(translateMatch[2]);
        return { x: x, y: y };
      }
    }
    return { x: 0, y: 0 };
  }

  const getRotateAttr = (el: SVGTextElement) => {
    const transformAttr = el.getAttribute("transform");
    if (transformAttr) {
      const rotateMatch = transformAttr.match(/rotate\([^)]+\)/);
      if (rotateMatch) {
        return rotateMatch[0];
      }
    }
    return null;
  }

  const moveTransformPosition = useCallback((el: SVGTextElement, moveX: number, moveY: number) => {
    const transformPosition = getTransformPosition(el);
    const newTranslate = `translate(${transformPosition.x + moveX}, ${transformPosition.y + moveY})`;
    const rotateAttr = getRotateAttr(el);
    let newTransform = newTranslate;
    if (rotateAttr) {
      newTransform = `${rotateAttr} ${newTranslate}`;
    }
    el.setAttribute("transform", newTransform);
  }, [])


  // 指定した要素の上に凡例テキストを追加
  const generateSpeedmeterText = useCallback((el: SVGTextElement, title: string) => {
    const parent = el.parentElement; // 親要素を取得
    if (!parent) return;

    el.style.setProperty("font-size", "17px");

    // 要素を複製
    const clonedElement = el.cloneNode(true) as SVGTextElement;
    clonedElement.textContent = title;

    // 位置を調整
    moveTransformPosition(clonedElement, 0, LEGEND_POSITION);

    // 元の要素の直前に挿入
    parent.insertBefore(clonedElement, el);
  }, [moveTransformPosition])

  // メーター上に表示したくない値を非表示にする
  const speedMeterTextVisible = useCallback((el: SVGTextElement) => {
    const content = el.textContent?.trim().replace("−", "-")

    // 空文字の場合は非表示
    if (content === undefined || isNaN(Number(content))) {
      el.textContent = "";
      return;
    }

    // CT下限・標準CT・目標CT・CT上限の場合は処理対象外
    if (
      Number(content) === getLowerBoundValue() ||
      Number(content) === standardCycleTime ||
      Number(content) === getTargetCycleTime() ||
      Number(content) === getUpperBoundValue()
    ) {
      return;
    }

    // 目盛り線用の値かつ10%刻みの値でない場合、開始値・終了値は非表示
    if (
      (
        !stopMainValues.includes(Number(content)) &&
        !generateRoundedNumbers(stopMainValues).includes(Number(content))
      ) ||
      Math.floor(stopMainValues[0] * 10) === Math.floor(Number(content) * 10) ||
      Math.floor(stopMainValues[stopMainValues.length - 1] * 10) === Math.floor(Number(content) * 10)
    ) {
      el.textContent = "";
      return;
    }

    // CT下限・標準CT・目標CT・CT上限に近い目盛りは値を表示しない
    const checkLowerMultiple = checkTargetCtValueDiff(getLowerBoundValue());
    const checkUpperMultiple = checkTargetCtValueDiff(getUpperBoundValue());
    const checkStandardMultiple = checkTargetCtValueDiff(standardCycleTime);
    const range = stopMainValues[stopMainValues.length - 1] - stopMainValues[0];
    const adjustSuffer = range * ADJUST_SUFFER_RATIO;
    if (removeFirstAndLast(stopMainValues).some((num: number) => {
      let adjust = adjustSuffer;
      if (
        (checkLowerMultiple !== 0 && num === getLowerBoundValue()) ||
        (checkUpperMultiple !== 0 && num === getUpperBoundValue()) ||
        (checkStandardMultiple !== 0 && num === standardCycleTime)
      ) {
        adjust = adjustSuffer * 2;
      }
      return Math.abs(num - Number(content)) < adjust;
    })) {
      el.textContent = "";
      return;
    }

    // 目盛り線の場合は細くする
    el.style.setProperty("font-weight", "100");
  }, [checkTargetCtValueDiff, getLowerBoundValue, getTargetCycleTime, getUpperBoundValue, standardCycleTime, stopMainValues])

  // 10%刻みで要素を追加
  const addPercentageSteps = useCallback((arr: number[]): number[] => {
    if (arr.length === 0) return [];
    const roundedNumbers = generateRoundedNumbers(arr);
    // 元の配列と統合し、重複を排除してソート
    const result = Array.from(new Set([...arr, ...roundedNumbers])).sort((a, b) => a - b);
    return result;
  }, []);

  // メーター最小値・最大値を元に10%刻みの値を生成
  const generateRoundedNumbers = (arr: number[]): number[] => {
    if (arr.length === 0) return [];

    // 配列の最小値と最大値を取得
    const min = Math.min(...arr);
    const max = Math.max(...arr);

    if (min > max || min === max) return [];

    // 10%刻みの値を追加
    const percentageStep = 0.1; // 10%
    const roundedNumbers: number[] = [];

    const step = (max - min) * percentageStep;
    const limit = Number((Math.floor(max * 10) / 10).toFixed(1));
    for (let i = min + step; i <= limit; i += step) {
      roundedNumbers.push(Number((Math.round(i * 10) / 10).toFixed(1)));
    }
    return roundedNumbers;
  }

  const adjustTargetCtValue = useCallback((arr: number[]) => {
    let targetCycleTime = getTargetCycleTime();

    if (!arr.length) {
      return targetCycleTime;
    }

    const range = arr[arr.length - 1] - arr[0];
    const lineWidth = range * LINE_WIDTH_RATIO;
    if (targetCycleTime > arr[arr.length - 1] - lineWidth) {
      targetCycleTime = arr[arr.length - 1] - lineWidth;
    }
    if (targetCycleTime < arr[0] + lineWidth) {
      targetCycleTime = arr[0] + (lineWidth / 10);
    }

    return Number(targetCycleTime.toFixed(2));
  }, [getTargetCycleTime])

  const removeFirstAndLast = (arr: any[]): any[] => {
    if (arr.length <= 2) return [];
    return arr.slice(1, -1);
  };

  const editSpeedmeterText = useCallback(() => {
    const elements = document.querySelectorAll<SVGTextElement>(
      "svg.speedometer text.segment-value"
    );

    elements.forEach((el) => {
      const content = el.textContent?.trim().replace("−", "-")
      if (content === undefined || isNaN(Number(content))) return;

      speedMeterTextVisible(el);

      let text = null;
      switch (Number(content)) {
        case getLowerBoundValue():
          text = generateTargetCtMultipleText(getLowerBoundValue(), "CT下限");
          if (text && text.position) {
            moveTransformPosition(el, text.position * -1, 0);
          }
          generateSpeedmeterText(el, text ? text.text : "CT下限");
          el.textContent = generateTargetCtMultipleValue(getLowerBoundValue());
          break;
        case standardCycleTime:
          text = generateTargetCtMultipleText(standardCycleTime, "標準CT");
          if (text && text.position) {
            moveTransformPosition(el, text.position * -1, 0);
          }
          generateSpeedmeterText(el, text ? text.text : "標準CT");
          el.textContent = generateTargetCtMultipleValue(standardCycleTime);
          break;
        case getUpperBoundValue():
          text = generateTargetCtMultipleText(getUpperBoundValue(), "CT上限");
          if (text && text.position) {
            moveTransformPosition(el, text.position * -1, 0);
          }
          generateSpeedmeterText(el, text ? text.text : "CT上限");
          el.textContent = generateTargetCtMultipleValue(getUpperBoundValue());
          break;
        case adjustTargetCtValue(stopMainValues):
          el.textContent = getTargetCycleTime().toString();
          // 他の値で目標CTが表示済みの場合目標CTのテキストを除去
          if (
            checkTargetCtValueDiff(standardCycleTime) !== 0 ||
            checkTargetCtValueDiff(getLowerBoundValue()) !== 0 ||
            checkTargetCtValueDiff(getUpperBoundValue()) !== 0
          ) {
            el.textContent = "";
            break;
          }
          // メーター範囲と同値の場合はメーター範囲側で目標CTを表示
          if (
            getTargetCycleTime() === stopMainValues[stopMainValues.length - 1] ||
            getTargetCycleTime() === stopMainValues[0]
          ) {
            el.textContent = "";
          }
          generateSpeedmeterText(el, "目標CT");
          break;
        case getTargetCycleTime():
          el.style.fontSize = "17px";
          break;
        default:
          break;
      }
    });

    const meters = document.querySelectorAll<SVGSVGElement>("svg.speedometer");
    meters.forEach((meter) => {
      adjustSVG(meter);
      generateLegend(meter, regendText);
    });
  }, [
    adjustSVG,
    standardCycleTime,
    adjustTargetCtValue,
    checkTargetCtValueDiff,
    generateLegend,
    generateSpeedmeterText,
    generateTargetCtMultipleText,
    generateTargetCtMultipleValue,
    getLowerBoundValue,
    getTargetCycleTime,
    getUpperBoundValue,
    moveTransformPosition,
    regendText,
    speedMeterTextVisible,
    stopMainValues,
  ])

  useEffect(() => {
    setShowSpeedmeter(false);

    const upperBoundValue = getUpperBoundValue();
    const lowerBoundValue = getLowerBoundValue();
    const boundDiff = (((upperBoundValue - lowerBoundValue) / 0.6) - (upperBoundValue - lowerBoundValue)) / 2;
    const endValue = Number((Math.round((upperBoundValue + boundDiff) * 100000) / 100000).toFixed(5));

    // 中央値がずれる問題を修正
    const startValue = Number((standardCycleTime - (endValue - standardCycleTime)).toFixed(5));

    let calcCurrent = currentCycleTime;

    setCurrentValueText(currentCycleTime.toString());

    // 現在の値がメーターを飛び出てしまう問題の調整
    const meterRange = endValue - startValue;
    const adjustValue = meterRange * 0.002;
    if (currentCycleTime < startValue) {
      calcCurrent = startValue - adjustValue;
    } else if (currentCycleTime > endValue) {
      calcCurrent = endValue + adjustValue;
    } else {
      setCurrentValueText('')
    }

    // 最終的な実績値をセット
    setCalcCurrentCycleTime(calcCurrent);

    setStopMainValues([
      startValue, // 開始点
      lowerBoundValue, // 標準範囲下限
      standardCycleTime, // 標準CT
      adjustTargetCtValue([startValue, endValue]), // 目標CT
      upperBoundValue, // 標準範囲上限
      endValue, // 終了点
    ]);
  }, [
    currentCycleTime,
    standardCycleTime,
    cycleTimeNormalRange,
    targetCycleTimeRange,
    targetCycleTimeType,
    adjustTargetCtValue,
    getLowerBoundValue,
    getUpperBoundValue
  ])

  useEffect(() => {
    setShowSpeedmeter(false);

    const sortStops = addPercentageSteps([...new Set(stopMainValues)].sort((x, y) => x - y));
    if (sortStops.length === 0 || (sortStops.length === 1 && !sortStops[0])) return;
    if (sortStops.length === 1) {
      sortStops.push(sortStops[0] + 100);
    }

    const meterRange = sortStops[sortStops.length - 1] - sortStops[0];
    const lineWidth = meterRange * LINE_WIDTH_RATIO;
    const tempStops = [
      ...sortStops,
      // 目盛り線の生成
      ...sortStops.map((value: number, index: number) => {
        // 最初と最後はそのまま
        if (index === 0 || index === sortStops.length - 1) return value;
        // 各設定値の場合は線を太くする
        if (
          value === getLowerBoundValue() ||
          value === standardCycleTime ||
          value === adjustTargetCtValue(sortStops) ||
          value === getUpperBoundValue()
        ) {
          return value + (lineWidth);
        }
        // その他の値（10毎の目盛）の場合は線を細くする
        return value + (lineWidth * LINE_WIDTH_THIN_RATIO);
      }),
    ];
    let stops = [...new Set(tempStops)].sort((x, y) => x - y)

    setCustomSegmentStops(stops);

    const segmentColors = stops.map((stop, index) => {
      // 最初と最後の値はグレー
      if (index === 0 || index === stops.length - 1) {
        return '#D9D9D9';
      }

      // 各設定値の場合は色を変える
      switch (stop) {
        case getLowerBoundValue():
          return '#FB1F1F';
        case standardCycleTime:
          return '#F7FB1F';
        case adjustTargetCtValue(stops):
          return '#30F536';
        case getUpperBoundValue():
          return '#FB1F1F';
      }

      // 10%毎の目盛りは黒線にする
      if (generateRoundedNumbers(stopMainValues).includes(stop)) {
        return '#000000';
      }

      // 標準CT範囲外はグレー
      if (stop < getLowerBoundValue() || stop >= getUpperBoundValue()) {
        return "#D9D9D9";
      }

      // 標準CT範囲内は青
      return '#65ACFF';
    });

    setSegmentColors(segmentColors);

    setTimeout(() => {
      editSpeedmeterText();
      setShowSpeedmeter(true);
    }, 500);
  }, [
    stopMainValues,
    addPercentageSteps,
    adjustTargetCtValue,
    editSpeedmeterText,
    getLowerBoundValue,
    getUpperBoundValue,
    standardCycleTime
  ])

  if (
    customSegmentStops.length <= 0
    || currentCycleTime === undefined
    || standardCycleTime === undefined
  ) {
    return (
      <>
      </>
    )
  }
  return (
    <>
      <div className="speedmeterContainer">
        <div style={{ opacity: showSpeedmeter ? 1 : 0 }}>
          <ReactSpeedometer
            key={customSegmentStops.join(",")}
            value={calcCurrentCycleTime}
            minValue={customSegmentStops[0]}
            maxValue={customSegmentStops[customSegmentStops.length - 1]}
            customSegmentStops={customSegmentStops}
            segmentColors={segmentColors}
            fluidWidth={true}
            ringWidth={100}
            needleColor="#877070"
            forceRender={true}
            currentValueText={currentValueText}
          />
        </div>
      </div>
      {!showSpeedmeter && (
        <div className="spinContainer">
          <Spin />
        </div>
      )}
    </>
  )
}

export default CycleTimeSpeedmeter;